$font-path: "/assets/fonts/";

@font-face {
    font-family: "Avenir";
    src: url("#{$font-path}Avenir-Medium.eot");
    src: url("#{$font-path}Avenir-Medium.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Avenir-Medium.woff2") format("woff2"),
    url("#{$font-path}Avenir-Medium.woff") format("woff"),
    url("#{$font-path}Avenir-Medium.ttf") format("truetype"),
    url("#{$font-path}Avenir-Medium.svg#Avenir Medium") format("svg");
    font-weight: 400;
}
@font-face {
    font-family: "Avenir";
    src: url("#{$font-path}Avenir-Heavy.eot");
    src: url("#{$font-path}Avenir-Heavy.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Avenir-Heavy.woff2") format("woff2"),
    url("#{$font-path}Avenir-Heavy.woff") format("woff"),
    url("#{$font-path}Avenir-Heavy.ttf") format("truetype"),
    url("#{$font-path}Avenir-Heavy.svg#Avenir Medium") format("svg");
    font-weight: 600;
}
@font-face {
    font-family: "Avenir";
    src: url("#{$font-path}Avenir-Black.eot");
    src: url("#{$font-path}Avenir-Black.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Avenir-Black.woff2") format("woff2"),
    url("#{$font-path}Avenir-Black.woff") format("woff"),
    url("#{$font-path}Avenir-Black.ttf") format("truetype"),
    url("#{$font-path}Avenir-Black.svg#Avenir Medium") format("svg");
    font-weight: 800;
}
