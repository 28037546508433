#address-autocomplete {
    .algolia-places-nostyle {
        display: block !important;
        width: 100%;

        .ap-nostyle-input {
            border-radius: 8px;
            padding-bottom: 8px;
            padding-inline-end: 10px;
            padding-inline-start: 10px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 8px;
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            text-decoration: inherit;
            text-indent: inherit;
            text-overflow: inherit;
            text-transform: inherit;
            text-align: inherit;
            white-space: inherit;
            color: inherit;
            display: inline-block;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            border: 1px solid #E1E9ED;
            outline: none;
            background: #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::placeholder {
                color: var(--ion-text-color);
                opacity: .5;
            }
        }
        .ap-nostyle-input-icon {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 40px;
            height: 100%;
            background: var(--ion-color-success);
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: 1px solid #E1E9ED;

            &.ap-nostyle-icon-clear {
                background: #FFF;
                border-left: none;

                svg {
                    fill:  var(--ion-text-color);
                }
            }

            &.ap-nostyle-icon-pin {
                display: none;
                background: var(--ion-color-success);
            }

            svg {
                fill: #fff;
            }
        }

        .ap-nostyle-dropdown-menu {
            background: #fff;
            box-shadow: 0px 4px 8px 0px #ccc;

            .ap-nostyle-dataset-places {
                .ap-footer {
                    display: none;
                }

                .ap-nostyle-suggestions {
                    .ap-nostyle-suggestion {
                        display: flex;
                        flex-direction: column;
                        padding: 5px;
                        border-bottom: 1px solid #E1E9ED;
                        .ap-suggestion-icon {
                            display: none;
                        }

                        .ap-name em {
                            font-style: normal;
                            color: var(--ion-color-success);
                        }
                    }
                }
            }
        }
    }
}