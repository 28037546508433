#report-selects {
    text-align: left;
    &.empty {
        border: 1px solid var(--ion-color-danger);
    }
}

#checkin-selects {
    border-color: var(--ion-color-danger);
    background: rgba(var(--ion-color-danger-rgb), 0.04);
    color: var(--ion-color-danger);
    text-align: left;
    &.is_clean {
        border-color: var(--ion-color-success);
        background: rgba(var(--ion-color-success-rgb), 0.04);
        color: var(--ion-color-success);
    }
}

.reports-list {
    .report-options {
        --padding-start: 0;
        --inner-padding-start: 20px;
        margin-bottom: 0;
    }
    .popover-content {
        width: calc(95vw - calc(5px + var(--ion-safe-area-left, 0px)));
        left: 5vw;
    }
    .clean {
        --color: var(--ion-color-success);
        --padding-start: 0;
        --inner-padding-start: 20px;
        margin-bottom: 0;
    }
    .not-clean {
        --color: var(--ion-color-danger);
        --padding-start: 0;
        --inner-padding-start: 20px;
        margin-bottom: 0;
        --inner-border-width: 0;
    }
}