:root .kabin-custom-select.select-interface-option {
    --padding-start: 0;

    &:last-child {
        --inner-border-width: 0;
    }
    &:first-child {
        margin-top: 6px;
    }

    ion-label {
        padding-left: 20px;
    }
}