// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
$path-assets: "assets/";

/** Ionic CSS Variables **/

:root {
    --ion-text-color: #113958;
    --ion-background-blue: #C7D6E0;
    --ion-color-primary: #113958;
    --ion-color-primary-rgb: 17, 57, 88;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0f324d;
    --ion-color-primary-tint: #294d69;
    --ion-color-secondary: #3C7DA3;
    --ion-color-secondary-rgb: 60, 125, 163;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #356e8f;
    --ion-color-secondary-tint: #508aac;
    --ion-color-tertiary: #458DB6;
    --ion-color-tertiary-rgb: 69, 141, 182;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #3d7ca0;
    --ion-color-tertiary-tint: #5898bd;
    --ion-color-success: #29837E;
    --ion-color-success-light: #E0EAEA;
    --ion-color-success-rgb: 41, 131, 126;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #24736f;
    --ion-color-success-tint: #3e8f8b;
    --ion-color-warning: #F6D04F;
    --ion-color-warning-rgb: 246, 208, 79;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d8b746;
    --ion-color-warning-tint: #f7d561;
    --ion-color-danger: #CB4D38;
    --ion-color-danger-light: #F7F0EF;
    --ion-color-danger-rgb: 203, 77, 56;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #b34431;
    --ion-color-danger-tint: #d05f4c;
    --ion-color-dark: #113958;
    --ion-color-dark-rgb: 17, 57, 88;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #0f324d;
    --ion-color-dark-tint: #294d69;
    --ion-color-medium: #8EB1C8;
    --ion-color-medium-rgb: 142, 177, 200;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #7d9cb0;
    --ion-color-medium-tint: #99b9ce;
    --ion-color-light: #F8F9FB;
    --ion-color-light-rgb: 248, 249, 251;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #dadbdd;
    --ion-color-light-tint: #f9fafb;

    --kabin-grey-text: #A0A0A0;
    --kabin-grey-text-opacity-50: #A0A0A080;
    --kabin-input-icon-color: #585858;

    --ion-font-family: 'Avenir', -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
    --ion-font-family-native: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
    --ion-background-color-light: #EEF2F5;
    --ion-form-border-color: #E1E9ED;
    --ion-padding-top-closure-icon: var(--status-bar-safe-padding);
    --ion-color-title: #26587B;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    --ion-color-grey: #C7C7C7;
    --ion-color-grey-rgb: 199,199,199;
    --ion-color-grey-contrast: #000000;
    --ion-color-grey-contrast-rgb: 0,0,0;
    --ion-color-grey-shade: #afafaf;
    --ion-color-grey-tint: #cdcdcd;

    --ion-color-grey-light: #F2F2F2;
    --ion-color-grey-light-rgb: 242,242,242;
    --ion-color-grey-light-contrast: #000000;
    --ion-color-grey-light-contrast-rgb: 0,0,0;
    --ion-color-grey-light-shade: #d5d5d5;
    --ion-color-grey-light-tint: #f3f3f3;
    
    --ion-color-pink: #EA6EB6;
    --ion-color-pink-rgb: 234,110,182;
    --ion-color-pink-contrast: #FFFFFF;
    --ion-color-pink-contrast-rgb: 255,255,255;
    --ion-color-pink-shade: #ce61a0;
    --ion-color-pink-tint: #ec7dbd;

    --ion-color-black: #1D1D1B;
    --ion-color-black-rgb: 29,29,27;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255,255,255;
    --ion-color-black-shade: #1a1a18;
    --ion-color-black-tint: #343432;

    --ion-color-green: #386937;
    --ion-color-green-rgb: 56,105,55;
    --ion-color-green-contrast: #ffffff;
    --ion-color-green-contrast-rgb: 255,255,255;
    --ion-color-green-shade: #315c30;
    --ion-color-green-tint: #4c784b;
    --ion-color-green-opacity-29: #3869374A;

    --ion-color-blue: #3259B3;
    --ion-color-blue-rgb: 50,89,179;
    --ion-color-blue-contrast: #ffffff;
    --ion-color-blue-contrast-rgb: 255,255,255;
    --ion-color-blue-shade: #2c4e9e;
    --ion-color-blue-tint: #476abb;

    --ion-color-red: #E94932;
    --ion-color-red-rgb: 233,73,50;
    --ion-color-red-contrast: #ffffff;
    --ion-color-red-contrast-rgb: 255,255,255;
    --ion-color-red-shade: #cd402c;
    --ion-color-red-tint: #eb5b47;

    --ion-color-yellow: #F0AD47;
    --ion-color-yellow-rgb: 240,173,71;
    --ion-color-yellow-contrast: #000000;
    --ion-color-yellow-contrast-rgb: 0,0,0;
    --ion-color-yellow-shade: #d3983e;
    --ion-color-yellow-tint: #f2b559;
}

@media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */
    /*
  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
  */
    /*
   * iOS Dark Theme
   * -------------------------------------------
   */
    /*
  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }
  */
    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
    /*
  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }

  ion-title.title-large {
    --color: white;
  }
  */
}

html {
  &.plt-capacitor {
    &.ios {
      --status-bar-safe-padding: 20px;
    }
    &.android {
      --status-bar-safe-padding: 20px;
    }
  }

  --status-bar-safe-padding: 0px;
}