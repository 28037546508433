// black
.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black-rgb);
	--ion-color-contrast: var(--ion-color-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-black-shade);
	--ion-color-tint: var(--ion-color-black-tint);
}

// white
.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

// grey
.ion-color-grey {
	--ion-color-base: var(--ion-color-grey);
	--ion-color-base-rgb: var(--ion-color-grey-rgb);
	--ion-color-contrast: var(--ion-color-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-grey-shade);
	--ion-color-tint: var(--ion-color-grey-tint);
}

// grey-light
.ion-color-grey-light {
	--ion-color-base: var(--ion-color-grey-light);
	--ion-color-base-rgb: var(--ion-color-grey-light-rgb);
	--ion-color-contrast: var(--ion-color-grey-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-grey-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-grey-light-shade);
	--ion-color-tint: var(--ion-color-grey-light-tint);
}

// pink
.ion-color-pink {
    --ion-color-base: var(--ion-color-pink);
	--ion-color-base-rgb: var(--ion-color-pink-rgb);
	--ion-color-contrast: var(--ion-color-pink-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
	--ion-color-shade: var(--ion-color-pink-shade);
	--ion-color-tint: var(--ion-color-pink-tint);
}

// green
.ion-color-green {
	--ion-color-base: var(--ion-color-green);
	--ion-color-base-rgb: var(--ion-color-green-rgb);
	--ion-color-contrast: var(--ion-color-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-green-shade);
	--ion-color-tint: var(--ion-color-green-tint);
}

// blue
.ion-color-blue {
	--ion-color-base: var(--ion-color-blue);
	--ion-color-base-rgb: var(--ion-color-blue-rgb);
	--ion-color-contrast: var(--ion-color-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-blue-shade);
	--ion-color-tint: var(--ion-color-blue-tint);
}

// red
.ion-color-red {
	--ion-color-base: var(--ion-color-red);
	--ion-color-base-rgb: var(--ion-color-red-rgb);
	--ion-color-contrast: var(--ion-color-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-red-shade);
	--ion-color-tint: var(--ion-color-red-tint);
}

// yellow 
.ion-color-yellow {
	--ion-color-base: var(--ion-color-yellow);
	--ion-color-base-rgb: var(--ion-color-yellow-rgb);
	--ion-color-contrast: var(--ion-color-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-yellow-shade);
	--ion-color-tint: var(--ion-color-yellow-tint);
}
